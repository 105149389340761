import React from "react"
import { Chrono } from "react-chrono";
import prof_pic from '../assets/casual_tech_prof_pic.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from "../assets/solarcar.PNG";

const MyTimeline = () => {
    const items = [
      {
        title: "Fall 2016",
        media: {
          name: "dunkirk beach",
          source: {
            url: "https://news.virginia.edu/sites/default/files/article_image/institutional_announcement_ss_header.jpg",
            type: "jpg"
          },
          type: "IMAGE"
        },
        cardTitle: "Started First Year @ University of Virginia (UVA)",
        cardDetailedText: "Served as Dorm Council President, Joined the Solar Car Team as Mechanical Engineer"
      },
      {
        title: "Summer 2017",
        media: {
          name: "ROMAC",
          source: {
            url: "https://engineering.virginia.edu/sites/default/files/styles/list-thumbnail/public/FFBTR%20%281%29.png",
            type: "PNG"
          },
          type: "IMAGE"
        },
        cardTitle: "Interned for ROMAC @ UVA",
        cardDetailedText:"Designed electromechanical fixtures for electromagnetic bearing testing."
      },
      {
        title: "Autumn 2017",
        media: {
          name: "battery",
          source: {
            url: "https://www.qats.com/cms/wp-content/uploads/Fig-10-1.jpg",
            type: "jpg"
          },
          type: "IMAGE"
        },
        cardTitle: "Sub-team Lead for Solar Car Team @ UVA",
        cardDetailedText: "Led the Aerobody and Battery Thermal System Team through first battery design process"
      },
      {
        title: "Summer 2018",
        media: {
          name: "ROMAC",
          source: {
            url: "https://engineering.virginia.edu/sites/default/files/styles/list-thumbnail/public/FFBTR%20%281%29.png",
            type: "PNG"
          },
          type: "IMAGE"
        },
        cardTitle: "Interned for ROMAC @ UVA",
        cardDetailedText: "Wrote code and scripts to analyze electromagnetic bearing designs."
      },
      {
        title: "Fall 2018",
        media: {
          name: "Solar Car",
          source: {
            url: "https://user-images.githubusercontent.com/28467603/130332037-d7fa6535-9ffc-4503-847f-acbe995dec9a.png",
            type: "PNG"
          },
          type: "IMAGE"
        },
        cardTitle: "Mechanical Engineering Director for Solar Car Team @ UVA",
        cardDetailedText: "Led various teams using a lean and agile approach through manufacturing and design."
      },
      {
        title: "Summer 2019",
        media: {
            name: "Rolls-Royce",
            source: {
              url: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a2/Rolls_royce_holdings_logo.svg/1200px-Rolls_royce_holdings_logo.svg.png",
              type: "PNG"
            },
            type: "IMAGE"
          },
        cardTitle: "Manufacturing Systems and Software Intern for Rolls-Royce",
        cardDetailedText: "Developed a desktop application (Python) that mitigated a product identification quality assurance issue by ~85% – saving the company over $30,000 per year and improving customer satisfaction."
      },
      {
        title: "Fall 2019",
        media: {
          name: "Solar Car",
          source: {
            url: "https://user-images.githubusercontent.com/28467603/130332037-d7fa6535-9ffc-4503-847f-acbe995dec9a.png",
            type: "PNG"
          },
          type: "IMAGE"
        },
        cardTitle: "President, Engineering Director of the Solar Car Team @ UVA",
        cardDetailedText: 
          "Led team through design and production of complex software and electromechanical systems, dramatically increased engagement of team members, boosted retention to 52 members."
      },
      {
        title: "Spring 2020",
        media: {
          name: "AutonomousRacing",
          source: {
            url: "https://user-images.githubusercontent.com/28467603/130332281-eb31a8c3-88cd-47ac-9ac2-d6c2ee98dc7f.png",
            type: "PNG"
          },
          type: "IMAGE"
        },
        cardTitle: "Teaching Assistant for CS4500 - Autonomous Racing",
        cardDetailedText: 
          "Helped students utilize ROS to integrate system of LIDAR and actuators for autonomous driving (C++ & Python). Assisted with software and algorithm development for demonstrations."
      },
      {
        title: "Summer 2020",
        media: {
          name: "Outrider",
          source: {
            url: "https://www.outrider.ai/wp-content/uploads/2020/10/PR-Outrider_three_autonomous_vehicles.jpg",
            type: "PNG"
          },
          type: "IMAGE"
        },
        cardTitle: "Systems Eng. Intern – Software, Simulation, and Testing @ Outrider",
        cardDetailedText: 
          "Fixed and improved broken rosbag visualization framework that was being used to post-process test missions – giving test and dev team tools to speed up test analysis and improve vehicle self-driving algorithm. Used ROS, C++, and Python."
      },
      {
        title: "Autumn 2020",
        media: {
          name: "UW",
          source: {
            url: "https://d28htnjz2elwuj.cloudfront.net/wp-content/uploads/2019/05/07160612/University-of-Washington.jpg",
            type: "PNG"
          },
          type: "IMAGE"
        },
        cardTitle: "Masters program @ University of Washington (UW) - Electrical and Computer Engineering Department.",
        cardDetailedText: 
          "Started a masters program with a focus on Embedded Systems, Computer Vision, and Web Development."
      },
      {
        title: "January 2021",
        media: {
          name: "ROMAC",
          source: {
            url: "https://miro.medium.com/max/511/1*tFdNLQpIn5sT5D5HEeCBHg.png",
            type: "PNG"
          },
          type: "IMAGE"
        },
        cardTitle: "Teaching Assistant for Introduction to Embedded Systems [UW - CSE474]",
        cardDetailedText: 
          "Taught C programming, embedded programming, and basic RTOS development for two consecutive quarters (Winter and Spring Quarter ~ 6 months)."
      },
      {
        title: "Winter & Spring Quarters 2021",
        media: {
          name: "ROMAC",
          source: {
            url: "https://blog.alaskaair.com/wp-content/uploads/2016/01/8697_ak_hero_plane_16x9_mbr_m1a.png?w=1024",
            type: "PNG"
          },
          type: "IMAGE"
        },
        cardTitle: "Software Engineer @ Alaska Airlines Sponsored UW Project",
        cardDetailedText: 
          "Won 2nd Place Award for best project out of 41 other UW teams. Built a dynamic web application using WebGL, React, and Node.js for interior engineers to visualize airplane interior; responsible for software architecture choices; integrated Jira, Slack & GitHub"
      },
      {
        title: "Summer 2021",
        cardTitle: "Software Engineer Intern @ Verathon Inc.",
        cardDetailedText: 
          "Developed software for bladder and prostate scanning ultrasound device. Helped the team with software fixes and upgrades for a software update release. Developed internal web-app tool using PHP to audit software vulnurability checks. Also, developed software to automate testing, significantly saving test costs."
      },
      {
        title: "2022",
        cardTitle: "Software Engineer @ Freefly Systems, Inc.",
        cardDetailedText: 
          "Building software to make Freefly the best drone solution in the world for industrial applications"
      }
  ];

    return (
      <div>
        <div style={{'text-align': 'center'}} class="center">
          <h1 style={{'margin-top': 40}}>Follow My Professional Journey:</h1>
          <h6 style={{"padding-top": "30px","padding-bottom": "30px", "word-wrap": "break-word", "width": "50%", "margin-left": "auto", "margin-right": "auto"}}>
            Some of my most significant intangible traits are committment, passion, and growth. 
            I think this timeline of my professional growth
            highlights these traits.
          </h6>
        </div>
        <div style={{width: "100%", height: "550px", margin: "0 auto"}}>
          <Chrono items={items} scrollable={{scrollbar: false}}
          mode="VERTICAL_ALTERNATING" slideShow useReadMore={false} cardHeight={100} cardWidth={500}/>
        </div>
      </div>
    )
  }

  export default MyTimeline
