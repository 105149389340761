import React, { Component } from 'react'
import pcb from '../assets/PCB_Image_cropped.jpg';
import telemetry from '../assets/telemetry.PNG';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import CardGroup from 'react-bootstrap/CardGroup';

import CardColumns from 'react-bootstrap/CardColumns';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button'
import Tab from 'react-bootstrap/Tab'

import style from "../index.css";

import alaskaImage from "../assets/alaskaproject.PNG";
import webpic from "../assets/webpic.PNG";

import ReactPlayer from 'react-player'


function projectHTML(project) {

    switch(project) {
        case "alaska":
            // code block
            return (
                <Card>
                    <Card.Img variant="top" src={alaskaImage} style={{'width':'80%', 'padding':'auto', 'margin':'auto'}} />
                    <Card.Body>
                        <Card.Title>Interactive Layout of Passenger Accomodation Web App - 2nd Place Award</Card.Title>
                        <Card.Text>
                            My team won second best project award among fourty-one other industry sponsored UW student teams, with our full-stack web application for an interactive airplane seats layout design tool.
                            I led the software architecture and deployment design, used MongoDB, Express.JS, AngularJS, Node.js, to ensure robust product development and Azure for deployment
                            I developed the backend using Node.js to handle client requests and MongoDB queries. I also developed the front-end using Angular, WebGL, Bootstrap
                            Set up DevOps – Git, Azure, JIRA, Slack
                        </Card.Text>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>Year: 2021</ListGroupItem>
                        <ListGroupItem>Project Management, Software Developer, Software Architect</ListGroupItem>
                    </ListGroup>
                    <Card.Body>
                        <Card.Link href="https://youtu.be/agzahYUpI3A">Watch Demo</Card.Link>
                        <Card.Link href="https://www.ece.uw.edu/entrepreneurship/entrepreneurial-capstone/">Award Link</Card.Link>
                    </Card.Body>
                </Card>
                );
        case "ros2":
            return (
                <Card>
                    <div>
                        <ReactPlayer url='https://www.youtube.com/watch?v=a5MA3Dhg1bI' style={{'width':'50%', 'height':'50%', 'padding':'auto', 'margin':'auto'}}/>
                    </div>
                    
                    <Card.Body>
                        <Card.Title>Using ROS2 to Autonomously Control LGSVL Simulation Vehicle</Card.Title>

                        <Card.Text>
                            After setting up the LGSVL Autonomous Vehicle simulation environment, I wrote a ROS2 package and node to use the LIDAR data to autonomously 
                            complete a simple drive around the race-track. The code interprets the sensor data and uses a PID controller to control the car. 
                        </Card.Text>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>Year: 2021</ListGroupItem>
                        <ListGroupItem>Computer Vision, Robotics, ROS2, Autonomous Driving</ListGroupItem>
                    </ListGroup>
                    <Card.Body>
                        <Card.Link href="https://www.youtube.com/watch?v=a5MA3Dhg1bI">Demo Video</Card.Link>
                        <Card.Link href="https://github.com/sandeshworld/indyRacePack">Repository</Card.Link>
                    </Card.Body>
                </Card>
            );
        case "roboticArm":
            return (
            <Card>
            <Card.Img variant="top" src="https://raw.githubusercontent.com/sandeshworld/roboticArmVisualizer/main/readmeImgs/guiImg.png" style={{'width':'60%', 'padding':'auto', 'margin':'auto'}} />
            <Card.Body>
                <Card.Title>Lightweight &amp; Customizable 2D-Robotic Arm Kinematic Simulator</Card.Title>
                <Card.Text>
                    I was having a difficult time finding a simple, customizable, and easy to use 2D Kinematic Simulator online, so I rapidly built one.
                    It is very simple to use and you only need Python. You can also see the End Effector position and joint angles at all time. I really want to 
                    improve it further so if you are interested in adding additional features, please reach out to me. 
                </Card.Text>
            </Card.Body>
            <ListGroup className="list-group-flush">
                <ListGroupItem>Year: 2021</ListGroupItem>
                <ListGroupItem>Software Development, Robotics, Rapid Development</ListGroupItem>
            </ListGroup>
            <Card.Body>
                <Card.Link href="https://github.com/sandeshworld/roboticArmVisualizer">Repository</Card.Link>
            </Card.Body>
            </Card> 
            );

        case "dashboard":
            return (
                <Card>
                    <div>
                        <ReactPlayer url='https://www.youtube.com/watch?v=pQiFm_vM6w8' style={{'width':'80%', 'padding':'auto', 'margin':'auto'}}/>
                    </div>
                                        
                    <Card.Body>
                        <Card.Title>Autonomous Vehicle Web Dashboard using ROS2 Data (NOVEL) </Card.Title>
                        <Card.Text>
                        I built a beautiful, simple data dashboard using React. This was a very challenging side project because I could not use pre-existing
                        ROS based JS libraries, because they were not built for ROS2. Therefore, I built a backend using ROS2 that retransmitted ROS2 topics of my choice to a Flask server over UDP (socket)
                        and then the Flask server uses SocketIO to send data to a SocketIO client in the React app. The React app interprets the data and displays a live view of the vehicle data.  
                        </Card.Text>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>Year: 2021</ListGroupItem>
                        <ListGroupItem>Robotics, Web Development, Autonomous Driving</ListGroupItem>
                    </ListGroup>
                    <Card.Body>
                        <Card.Link href="https://github.com/sandeshworld/ros2_to_UDP_to_SocketIO_bridge">Networking Repository</Card.Link>
                        <Card.Link href="https://github.com/sandeshworld/web_based_autonomous_vehicle_dashboard">React Repository</Card.Link>
                    </Card.Body>
                </Card>
            );
        case "flight-search":
            return (
                <Card>
                    <div>
                        <ReactPlayer url='https://youtu.be/Uo-0H9Fsq30' style={{'width':'80%', 'padding':'auto', 'margin':'auto'}}/>
                    </div>
                                        
                    <Card.Body>
                        <Card.Title>Basic Flight Tickets Search Website (ReactJS + Gordian Software API)</Card.Title>
                        <Card.Text>
                            Using ReactJS, I built this tool that utilizes Gordian Software flight booking and ancillary purchasing API to take user inputs to search for real one-way Spirit Airline flights for any day of their choice. 
                            I wanted to replicate flight search engines like SkyScanner and PriceLine at an elementary level. I built this quickly as a MVP to show feasibility of working with Gordian Software's commercial API and to display React features.
                        </Card.Text>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>Year: 2021</ListGroupItem>
                        <ListGroupItem>Web Development, APIs, UI</ListGroupItem>
                    </ListGroup>
                    <Card.Body>
                        <Card.Link href="https://youtu.be/Uo-0H9Fsq30">Demo Video</Card.Link>
                        <Card.Link href="https://github.com/sandeshworld/">Reach Out For Code (prop. reasons)</Card.Link>
                    </Card.Body>
                </Card>
                );
        case "conv":
            return (
                <Card>
                    <Card.Img variant="top" style={{'width':'55%', 'padding':'auto'}} class="center" src="https://user-images.githubusercontent.com/28467603/115445830-384dfc00-a1cb-11eb-9d3e-14e8092cdd42.png" />
                    <Card.Body>
                        <Card.Title>Image Segmentation on Roads using Convolutional Neural Network</Card.Title>
                        <Card.Text>
                        With a colleague, I built a CNN to detect drivable surface area on road images. I developed the model and the training pipeline from scratch using Python,
                        PyTorch, and Numpy.
                        </Card.Text>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>Year: 2021</ListGroupItem>
                        <ListGroupItem>Machine Learning, Computer Vision, Robotics, Autonomous Driving</ListGroupItem>
                    </ListGroup>
                    <Card.Body>
                        <Card.Link href="https://github.com/sandeshworld/roadDetectionOnImages/blob/main/drivableAreaFinalReport.pdf">Paper</Card.Link>
                    </Card.Body>
                </Card>
            );
        case "f1":
            return (
                <Card>
                    <Card.Img variant="top"  style={{'width':'80%', 'padding':'auto'}} class="center" src="https://img.youtube.com/vi/K72mnMkSysU/0.jpg"/>
                    <Card.Body>
                        <Card.Title>Autonomously Racing F1/10th Car </Card.Title>
                        <Card.Text>
                        My team and I assembled vehicle component (actuators and LIDAR) and wrote autonomy algorithms in C++ and Python using the ROS framework.
                        At the final competition, we won the award for fastest lap. 
                        </Card.Text>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>Year: 2019</ListGroupItem>
                        <ListGroupItem>Robotics, ROS, LIDAR, Autonomous Driving, Project Management</ListGroupItem>
                    </ListGroup>
                    <Card.Body>
                        <Card.Link href="https://github.com/sandeshworld/autonomousracing_ferrari_19/wiki">Code + Documentation</Card.Link>
                    </Card.Body>
                </Card>
            );
        case "embed":
            return (
                    <Card>
                        <Card.Img variant="top" src={pcb} style={{'width':'80%', 'padding':'auto'}} class="center"/>
                        <Card.Body>
                            <Card.Title>Embedded System Hardware and Software for Solar Powered Car</Card.Title>
                            <Card.Text>
                                I contributed to PCB design, manufacturing, and Embedded Code for Solar Car Team. I used KiCAD to design PCB layout.
                                I also played a significant role in writing vehicle control code and completing code tests.   
                            </Card.Text>
                        </Card.Body>
                        <ListGroup className="list-group-flush">
                            <ListGroupItem>Year: 2018-2019</ListGroupItem>
                            <ListGroupItem>Embedded System Design, Software Development, Project Management</ListGroupItem>
                        </ListGroup>
                        <Card.Body>
                            <Card.Link href="https://github.com/solarcaratuva/EmbeddedSystem">Repository</Card.Link>
                            <Card.Link href="https://www.solarcaratuva.org/">Team Link</Card.Link>
                        </Card.Body>
                    </Card>
            );
        
        case "gimbal":
            return (
                <Card>
                    <video autoplay src="https://cdn-shop.adafruit.com/product-videos/1024x768/1967-02.mp4" style={{'width':'70%', 'padding':'auto'}} class="center" />
                    <Card.Body>
                        <Card.Title>Gimbal + Camera System that Detects Fire and Tracks It</Card.Title>
                        <Card.Text>
                            I built a servo-arm system with an infrared camera that identifies fire and stabilizes itself to keep the fire at the center of the image.
                            A potential application is to detect fire from a drone and to put out the fire.
                        </Card.Text>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>Year: 2019-2020</ListGroupItem>
                        <ListGroupItem>Drones, Robotics Software Development, Controls</ListGroupItem>
                    </ListGroup>
                    <Card.Body>
                        <Card.Link href="https://github.com/sandeshworld/fire_tracking">Repository</Card.Link>
                    </Card.Body>
                </Card>
            );
        case "telemetry":
            return (
                <Card>
                        <Card.Img variant="top" src={telemetry} />
                        <Card.Body>
                            <Card.Title>Solar Car Telemetry Application</Card.Title>
                            <Card.Text>
                                I led the Telemetry team to creating the team's first ever web based telemetry dashboard using Flask and Socket.io.
                                The project involved defining requirements and researching best practices. I led weekly check-ins and demo updates.
                            </Card.Text>
                        </Card.Body>
                        <ListGroup className="list-group-flush">
                            <ListGroupItem>Year: 2019-2020</ListGroupItem>
                            <ListGroupItem>Product Management, Software Developer, Embedded Systems</ListGroupItem>
                        </ListGroup>
                        <Card.Body>
                            <Card.Link href="https://github.com/solarcaratuva/Telemetry">Repository</Card.Link>
                        </Card.Body>
                </Card>
            );
        case "controls":
            return (
                <Card>
                    <Card.Img variant="top" src="https://user-images.githubusercontent.com/28467603/115431162-d8028e80-a1b9-11eb-9fa4-70ff1a4419d8.png" style={{'width':'80%', 'padding':'auto', 'margin':'auto'}} />
                    <Card.Body>
                        <Card.Title>Balancing an Inverted Pendulum using Propellers (Control System)</Card.Title>
                        <Card.Text>
                            I successfully led my project team to build a self-balancing inverted pendulum despite dealing with significant sensor noise and a highly non-linear system.
                            I wrote most of the software for interacting with the Inertial Measurement Unit and applying control to the actuators. The most complicated system to be successfully controlled in the class.
                        </Card.Text>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>Year: 2019</ListGroupItem>
                        <ListGroupItem>Project Management, Robotics, Controls, Raspberry Pi, Sensors</ListGroupItem>
                    </ListGroup>
                    <Card.Body>
                        <Card.Link href="https://github.com/sandeshworld/AutoControlsProject/wiki">Repository Wiki</Card.Link>
                        <Card.Link href="https://youtu.be/LuNxR40NLhc">Demo Video</Card.Link>
                    </Card.Body>
                </Card>
            );
        case "gameoflife":
            return (
                <Card>
                    <Card.Img variant="top" src="https://raw.githubusercontent.com/sandeshworld/conwaysgameoflife/master/mycgolGUI.png" style={{'width':'80%', 'padding':'auto', 'margin':'auto'}}/>
                    <Card.Body>
                        <Card.Title>Conway's Game of Life Simulator GUI</Card.Title>
                        <Card.Text>
                            Some of John Conway's work has really intrigued me. I was inspired to create a Game of Life interactive simulator where
                            the user can click on various squares on the grid to select initial conditions, then apply the rules to the initial conditions,
                            and visualize what happens at each timestep.
                        </Card.Text>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>Year: 2020-2021</ListGroupItem>
                        <ListGroupItem>Application Development, Just for Fun, Mathematics</ListGroupItem>
                    </ListGroup>
                    <Card.Body>
                        <Card.Link href="https://github.com/sandeshworld/conwaysgameoflife">Repository</Card.Link>
                    </Card.Body>
                </Card>
            );
        case "huffmanEncoding":
            return (
                <Card>
                    <Card.Img variant="top" src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Huffman_tree_2.svg/1280px-Huffman_tree_2.svg.png" style={{'width':'80%', 'padding':'auto', 'margin':'auto'}}/>
                    <Card.Body>
                        <Card.Title>Data Compression and Decompression Using Huffman Coding</Card.Title>
                        <Card.Text>
                            I wrote a Huffman Coding data compression algorithm for a class project.
                        </Card.Text>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>Year: 2018</ListGroupItem>
                        <ListGroupItem>Algorithm Development, C++</ListGroupItem>
                    </ListGroup>
                    <Card.Body>
                        <Card.Link href="https://github.com/sandeshworld/data_compression_decompression">Repository</Card.Link>
                    </Card.Body>
                </Card>
            );
            case "personalwebsite":
                return (
                    <Card>
                        <Card.Img variant="top" src={webpic} style={{'width':'80%', 'padding':'auto', 'margin':'auto'}} />
                        <Card.Body>
                            <Card.Title>My Professonal Website</Card.Title>
                            <Card.Text>
                                I built this website mostly from scratch using React and Bootstrap. I used various React libraries for certain features but the overall design and implementation is original.
                            </Card.Text>
                        </Card.Body>
                        <ListGroup className="list-group-flush">
                            <ListGroupItem>Year: 2021</ListGroupItem>
                            <ListGroupItem>Web Development, React, JavaScript, CSS, HTML</ListGroupItem>
                        </ListGroup>
                        <Card.Body>
                            <Card.Link href="https://sandesh-banskota.com/">Website Link</Card.Link>
                        </Card.Body>
                    </Card>
                );
        default:
          return (
              <br></br>
          );
      }    
}

function allProjects() {
    return (
        <Container class="yellow_back_2">
            <Row style={{'margin-top': '40px'}}>
                <Col>
                    {projectHTML("alaska")}
                </Col>
                <Col>
                    {projectHTML("flight-search")}
                </Col>
            </Row>
            <Row style={{'margin-top': '40px'}}>
                <Col md={6}>
                    {projectHTML("dashboard")}
                </Col>
                <Col md={6}>
                    {projectHTML("ros2")}
                </Col>
            </Row>
            <Row style={{'margin-top': '40px'}}>
                <Col>
                    {projectHTML("conv")}
                </Col>
                <Col>
                    {projectHTML("f1")}
                </Col>
            </Row>
            <Row style={{'margin-top': '40px'}}>
                <Col>
                    {projectHTML("embed")}
                </Col>
                <Col>
                    {projectHTML("gimbal")}
                </Col>
            </Row>
            <Row style={{'margin-top': '40px'}}>
                <Col>
                    {projectHTML("telemetry")}
                </Col>
                <Col>
                    {projectHTML("controls")}
                </Col>
            </Row>
            <Row style={{'margin-top': '40px'}}>
                <Col>
                    {projectHTML("roboticArm")}
                </Col>
                <Col>
                    {projectHTML("personalwebsite")}
                </Col>        
            </Row>
            <Row style={{'margin-top': '40px'}}>
                <Col>
                    {projectHTML("huffmanEncoding")}
                </Col>
                <Col>
                    {projectHTML("gameoflife")}
                </Col>        
            </Row>

            
        </Container>
    );
}

function robotics() {
    return (
        <Container class="yellow_back_2">
            <Row style={{'margin-top': '40px'}}>
                <Col md={6}>
                    {projectHTML("roboticArm")}
                </Col>
                <Col md={6}>
                    {projectHTML("dashboard")}
                </Col>
            </Row>
            <Row style={{'margin-top': '40px'}}>
                <Col>
                    {projectHTML("conv")}
                </Col>
                <Col>
                    {projectHTML("ros2")}
                </Col>
            </Row>
            <Row style={{'margin-top': '40px'}}>
                <Col>
                    {projectHTML("embed")} 
                </Col>
                <Col>
                    {projectHTML("f1")} 
                </Col>
            </Row>
            <Row style={{'margin-top': '40px'}}>
                <Col>
                    {projectHTML("telemetry")} 
                </Col>
                <Col>
                    {projectHTML("controls")} 
                </Col>
            </Row>
            <Row style={{'margin-top': '40px'}}>
                <Col>
                    {projectHTML("f1")} 
                </Col>
            </Row>
        </Container>
    );
}

function awardWinning() {
    return (
        <Container class="yellow_back_2">

            <Row style={{'margin-top': '40px'}}>
                <Col>
                    {projectHTML("alaska")}
                </Col>
                <Col>
                    {projectHTML("f1")} 
                </Col>
            </Row>

            <Row style={{'margin-top': '40px'}}>
                <Col>
                    {projectHTML("controls")} 
                </Col>

                <Col>
                
                </Col>
            </Row>

        </Container>
    );
}

function webDevelopment() {
    return (
        <Container class="yellow_back_2">
            <Row style={{'margin-top': '40px'}}>
                <Col>
                    {projectHTML("alaska")}
                </Col>
                <Col>
                    {projectHTML("flight-search")}
                </Col>
            </Row>
            <Row style={{'margin-top': '40px'}}>
                <Col>
                    {projectHTML("telemetry")}
                </Col>
                <Col md={6}>
                    {projectHTML("dashboard")}
                </Col>
            </Row>
            <Row style={{'margin-top': '40px'}}>
                <Col>
                    {projectHTML("personalwebsite")}
                </Col>
                <Col md={6}>
                </Col>
            </Row>
        </Container>
    );
}

function projectManagement() {
    return (
        <Container class="yellow_back_2">
            <Row style={{'margin-top': '40px'}}>
                <Col>
                    {projectHTML("alaska")}
                </Col>
                <Col>
                    {projectHTML("f1")}
                </Col>

            </Row>

            <Row style={{'margin-top': '40px'}}>
                <Col>
                    {projectHTML("embed")}
                </Col>
                <Col>
                    {projectHTML("telemetry")}
                </Col>
            </Row>
            <Row style={{'margin-top': '40px'}}>
                <Col>
                    {projectHTML("controls")} 
                </Col>
                <Col>
                    
                </Col>
            </Row>
        </Container>
    );
}

function random() {
    return (
        <Container class="yellow_back_2">
            <Row style={{'margin-top': '40px'}}>
                <Col>
                    {projectHTML("gameoflife")}
                </Col>
                <Col>
                    {projectHTML("huffmanEncoding")} 
                </Col>        
            </Row>
        </Container>
    );
}

// Documentation:
//the class style allows me to render and have React automatically update and call the render function when this.state is changed using the this.setState function.
class Projects extends Component {
    constructor(props) {
            super(props);
            this.state = {
                display: allProjects()
                
            }
        }
    

    updateContent = (type) => {
        //using setState tells react to rerender
        switch(type) {
            case "all":
                this.setState({display: allProjects()});
                break;
            case "awardwinning":
                this.setState({display: awardWinning()});
                break;
            case "robotics":
                this.setState({display: robotics()});
                break;
            case "webdev":
                this.setState({display: webDevelopment()});
                break;
            case "pm":
                this.setState({display: projectManagement()});
                break;
            case "others":
                this.setState({display: random()});
                break;
            
            default:
                break;
        }
            
        
    }


    render() {

        return (
            <div>
                <div>
                    <div style={{'padding-top': '40px'}}>
                        <h1 style={{'text-align': 'center'}}>Some of My Notable Projects:</h1>
                    </div>
                    <div style={{'width': '57em','alignItems': 'center', marginLeft:'auto', marginRight:'auto'}}>
                        <p style={{'text-align': 'center', 'padding-top': '40px'}}>Filter By: </p>
                        <Button variant="primary" onClick={() => this.updateContent('all')}>All Projects</Button>{' '}
                        <Button variant="secondary" onClick={() => this.updateContent('awardwinning')}>Award Winning</Button>{' '}
                        <Button variant="success" onClick={() => this.updateContent('robotics')}>Robotics</Button>{' '}
                        <Button variant="warning" onClick={() => this.updateContent('webdev')}>Web Development</Button>{' '}
                        <Button variant="light" onClick={() => this.updateContent('pm')}>Project Management</Button>
                        <Button variant="dark" onClick={() => this.updateContent('others')}>Other/Random</Button>{''}
                    </div>
                    {this.state.display}
                </div>

            </div>


        )
    }


}

export default Projects
