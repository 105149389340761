import logo from './logo.svg';
import './App.css';
import Header from './components/Header'
import BriefIntroduction from './components/BriefIntroduction'
import Projects from './components/Projects'
import MyTimeline from './components/myTimeline'
import KeyInfo from './components/KeyInfo';
import HobbiesClass from './components/Hobbies';


import 'bootstrap/dist/css/bootstrap.min.css';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { SocialIcon } from 'react-social-icons';
import ReactDOM from 'react-dom';
import { Canvas } from "react-three-fiber";

import seaBackground from "./assets/seattle_background.jpg";


var output = true;
var content;

function App() {
  return (
    <div>
      <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-0 justify-content-center" style={{'margin-top':5}}>
        <Tab eventKey="home" activated title="Home">
          <KeyInfo/>
        </Tab>
        <Tab eventKey="timeline" title="Professional Timeline">
          <MyTimeline class="timeline"/>
        </Tab>
        <Tab eventKey="projects" title="Projects">
          <div style={{"background-color": "rgb(207, 238, 250)"}}>
            <Projects />
          </div>
        </Tab>
        <Tab eventKey="Hobbies" title="Hobbies">
            <h1 style={{'text-align': 'center', 'margin-top': 30}}>Outside of work, I like ...</h1>
            <p style={{'text-align': 'center', 'margin-top': 30}}>Click and drag to check out out my many sides. More details (including videos and pictures) below.</p>
            <HobbiesClass/>
        </Tab>
        <Tab eventKey="contact" title="Contact">
          <div className="introduction">
            <p>
                Reach out to me on LinkedIn and feel free to check out my Github portfolio:
            </p>
            <br></br>
            <div className="social-icon">
                <SocialIcon bgColor="blue" url="https://www.linkedin.com/in/sandesh-banskota-45a000163/"/>
                <SocialIcon bgColor="black" url="https://github.com/sandeshworld"/> 
            </div>
          </div>
          
        </Tab>
      </Tabs>
    </div>
    
  );
}

export default App;
