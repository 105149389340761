import prof_pic from '../assets/casual_tech_prof_pic.jpg';

const Header = ({ title }) => {
    return (
        <header className="header">
            <h1> {title} </h1>
            <h1> I am Sandesh Banskota. </h1>
            <img src={prof_pic} height="300"/>

            <h1> Welcome to my portfolio website, built from scratch using React!</h1>
        </header>
    )
}

Header.defaultProps = {
    title: "Hi! Nice to Meet You!"
}

// can use Header.propTypes = { title: PropTypes.string.isRequired} to define required types (i.e. require title to be a string)

export default Header
