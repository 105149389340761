import { SocialIcon } from 'react-social-icons';
import prof_image from '../assets/profPic2.jpg';
import telemetry from '../assets/telemetry.PNG';
import seattle_back from '../assets/seattle_picture.jpg';



import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';

const KeyInfo = () => {
return (
    <div class='seattle_back'>
    <Container>
    <Row>
    <Col md={{ span: 6, offset: 0 }}>
            <div >
                <br/>
                <Card border="primary" style={{'margin-bottom': 150}}>
                    <Card.Body>
                        <Card.Title style={{fontSize: 30, textAlign: 'center'}}>Sandesh Banskota</Card.Title>
                        <Card.Img variant="top" style={{'width':'50%', 'padding':'auto', 'margin-bottom':'15px', 'margin-top':'15px'}} class="center" src={prof_image} />
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>
                            <Card.Text>
                            I am a software engineer and roboticist. I enjoy working on meaningful opportunities
                            that are beneficial for humanity and our planet.
                            </Card.Text>                    
                        </ListGroupItem>
                        <ListGroupItem>
                            <b>Domains:  </b>
                            Software Development, Android and iOS Development, Embedded Systems, Robotics, Autonomous Vehicles, Machine Learning, Computer Vision</ListGroupItem>
                        <ListGroupItem>
                            <b>Technical Skills:  </b>
                            Python, Java, C/C++, Swift, Javascript, Robotic Operating System, Embedded Development, Full-Stack Web Development, Sensors, 
                            PyTorch, ML/CV, Robotic Manipulation
                        </ListGroupItem>
                        <ListGroupItem>
                            <b>Soft Skills: </b>
                            Teamwork, Project Management, DevOps, Documentation
                        </ListGroupItem>
                        <ListGroupItem>
                            <b>Connect:  </b>
                            <div className="social-icon introduction">
                                <SocialIcon bgColor="blue" url="https://www.linkedin.com/in/sandesh-banskota-45a000163/"/>
                                <SocialIcon bgColor="black" url="https://github.com/sandeshworld"/> 
                            </div>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </div>
        </Col>
        <Col md={{ span: 5, offset: 1 }}>
            <div>
                <h3 style={{'text-align': 'center', 'margin-top': 30}}>
                    Hello! Namaste! Bienvenidos!
                </h3>
                <br></br>
                <h3 style={{'text-align': 'center', 'margin-top': 10, 'margin-bottom': 10}}>
                    About Me:
                </h3>

                <p>
                    I currently live in Seattle, working full-time as a software engineer at Freefly Systems, Inc. My interests lie in Software Development, ML/CV, and Robotics.
                </p>
                <p>
                    Before moving to Seattle, I graduated from the University of Virginia with a
                    B.S. in Mechanical Engineering and a minor in Computer Science. My specialization was in robotics
                    and controls. At UVA, I was dedicated to the Solar Car Team, where I spent my final year as the
                    Engineering Director and president. I was also a TA for F1/10 Autonomous Racing, 
                    a course pioneering autonomous driving curriculum in universities across the country. 
                </p>
                <p>
                    At the University of Washington, I TA-ed two consecutive quarters of a 400-level embedded systems (software and hardware) course.
                    I was also a key contributor in a three person team working on a industry sponsored capstone web-dev project that won second place for best project out of 41 other teams.
                    Overall, my specialization was in software engineering, computer vision, and robotics. 
                </p>
                <p>
                    Outside of school, I have interned at the startup <a href="https://www.outrider.ai/">Outrider.ai</a>,
                    where I helped build the simulation environment for the software engineering team and developed tools
                    for field test analysis. I also interned at <a href="https://www.rolls-royce.com/">Rolls-Royce</a> where I assisted with the launch of new products
                    and improved manufacturing throughput (with significant cost savings) by developing a process automating
                    Windows application. While at UW, I interned at <a href="https://www.verathon.com/">Verathon Inc</a>, a biomedical device company, as a Software Engineer,
                    working on the ultra-sound bladder scan product and developing internal tools.    
                </p>
                <p>
                    In my free time, I enjoy hiking, making music, and reading. Check out more in my hobbies section.
                </p>
            </div>
        </Col>
    </Row>
</Container>
</div>
)};


export default KeyInfo;