import React, { Component } from 'react'
import * as THREE from 'three'
import ReactDOM from 'react-dom'
import THREEx from './threex.dynamictexture.js';
import { OrbitControls } from 'three-stdlib';
import  "../index.css";
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import GrandTeton from '../assets/hobbies/delta_lake.jpg';
import Elbert from '../assets/hobbies/mt_elbert.jpg';
import SnowForest from '../assets/hobbies/snow_forest.jpg';

class HobbiesClass extends Component {
    constructor(props) {
      super(props)
      // this.raycaster = new THREE.Raycaster();
      // this.mouse = new THREE.Vector2();
    }

    createFaceTextures() {
      var face_textures = [];
      var i;
      var strings = ['Making Music', 'Hiking & Camping', 'Reading', 'Side Projects', 'Playing Basketball', 'Travelling']
      for(i = 0; i < 6; i++) {
        var dynamictexture = new THREEx.DynamicTexture(512, 512);
        dynamictexture.context.font = "bolder 40px arial";
        dynamictexture.texture.needsUpdate = true;
        dynamictexture.clear('#444444').drawText(strings[i], undefined, 256, 'black');
        face_textures.push(dynamictexture);
      }
      return face_textures;
    }

    componentDidMount() {
      //initialize raycaster
      // this.initializeRaycaster();

      var width = 500;
      var height = 500;
      //adding text to faces
      this.dynamictexture = new THREEx.DynamicTexture(512, 512);
      var face_textures = this.createFaceTextures();
      var materials = [
        new THREE.MeshBasicMaterial({map: face_textures[0].texture}),
        new THREE.MeshBasicMaterial({map: face_textures[1].texture}),
        new THREE.MeshBasicMaterial({map: face_textures[2].texture}),
        new THREE.MeshBasicMaterial({map: face_textures[3].texture}),
        new THREE.MeshBasicMaterial({map: face_textures[4].texture}),
        new THREE.MeshBasicMaterial({map: face_textures[5].texture})
      ];

      var scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(
        75,
        width / height,
        0.1,
        2000
      )
      var renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize( width, height );
      renderer.setClearColor('#ffffff')
      this.mount.appendChild( renderer.domElement );
    
      var geometry = new THREE.BoxGeometry( 2, 2, 2 );
      const edges = new THREE.EdgesGeometry( geometry );
      const line = new THREE.LineSegments( edges, new THREE.LineBasicMaterial( { color: 0x000000 } ) )
      var material = new THREE.MeshStandardMaterial( { color: 0x7e31eb } );
      var cube = new THREE.Mesh(geometry, materials);
      scene.add( cube );
      const light = new THREE.HemisphereLight( 0xffffff, 0x080820, 1 );
      scene.add( light );
      camera.position.z = 2;
      scene.add(line);

      scene.rotation.x += 3.14/4.0
      scene.rotation.y += 0
      scene.rotation.z += 3.14/4.0

      var controls = new OrbitControls( camera, renderer.domElement );
      controls.maxDistance = 3;
      controls.minDistance = 3;

      //controls.update() must be called after any manual changes to the camera's transform
      // this.camera.position.set( 0, 20, 100 );
      controls.update();
      
      
      // var onMouseClick = function(event) {
      //     const pointer = new THREE.Vector2();
      //     const raycaster = new THREE.Raycaster();

        
      //     pointer.set( ( event.clientX / window.innerWidth ) * 2 - 1, - ( event.clientY / window.innerHeight ) * 2 + 1 );

      //     raycaster.setFromCamera( pointer, camera );

      //     const intersects = raycaster.intersectObjects( scene.children );

      //     if ( intersects.length > 0 ) {

      //       const intersect = intersects[ 0 ];

      //       // delete cube

      //       alert(intersect)
      //     }
      // }
      
      //adding event listener for mouse move
      // window.addEventListener('click', onMouseClick, false);

      var animate = function () {
                requestAnimationFrame( animate );
                scene.rotation.x += 0.003;
                scene.rotation.y += 0.005;
                scene.rotation.z += 0.007;
                controls.update()
                renderer.render( scene, camera );
            };
            animate();
        }
    
    render() {
        return (
          <div >
              <div ref={ref => (this.mount = ref)} />
              <div style={{'text-align': 'center', 'margin-top': 30}}>
                <h1>Making Music:</h1>
                <p>Check out some music I have produced in my spare time.</p>
                <Container>
                  <Row>
                    <Col>
                    <iframe width="350" height="250" src="https://www.youtube.com/embed/y-8xQugmTO0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Col>
                    <Col>
                    <iframe width="350" height="250" src="https://www.youtube.com/embed/DIWy5W9UzWY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Col>
                    <Col>
                    <iframe width="350" height="250" src="https://www.youtube.com/embed/u-nMG6gCS6I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Col>
                  </Row>
                </Container>
                <br>
                </br>
                <h1>Hiking and Travelling: </h1>
                <p>I find solace in nature. Here are some pictures from some of my trips.</p>
                <Container>
                  <Row>
                    <Col>
                    <img src={GrandTeton} width='350'></img>
                    </Col>
                    <Col>
                    <img src={Elbert} width='350'></img>
                    </Col>
                    <Col>
                    <img src={SnowForest} width='350'></img>
                    </Col>
                  </Row>
                </Container>
                <br/>
                <br/>
                <br>
                </br>
                <h1>Side Projects: </h1>
                <p style={{marginLeft: 50, marginRight: 50, paddingRight: 50, paddingLeft: 50}}>One of my hobbies is to work on side projects. Most often, they involve robotics and embedded development.
                I also tinker with building web and desktop applications. 
                Additionally, I am fascinated by things like geography and meteorology. I love working with free API provided by NOAA. Follow me on GitHub to check out what I am up to. 
                Reach out to me if you want to talk about any of my hobbies!</p>
              </div>
          </div>
            
        )
    }
  }
  
export default HobbiesClass